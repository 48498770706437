.bat-bottomposition-cta-gloit {
  position: fixed; 
  bottom: 65px; 
  left: 50%; 
  transform: translateX(-50%);
  width: fit-content;
  margin-inline: auto;
  text-align: center;
  display: block;
  opacity: 0; 
  transition: opacity 0.5s ease; 
  pointer-events: none; 
  z-index: 999;

  .cta-bottom {
      display: inline-block;
      padding: 9px 30px;
      max-width: 300px;
      border-radius: 100px;
      background: var(--Glo-CTA-Gradient, linear-gradient(268deg, #CD4A04 0.65%, #ED7D30 100%));
      border: solid 2px transparent;
      background-origin: border-box;
      color: white;
      text-decoration: none;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 15.6px */
      letter-spacing: 0.7px;
  }
}

.bat-bottomposition-cta-gloit.visible {
  opacity: 1; 
  pointer-events: auto; 
}
