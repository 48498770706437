//Variables
@import './global/variables';
@import './global/authoring';

//Grid
@import './grid/aem-grid';

//Base
@import './base/fonts';
@import './base/icomoon';
@import './base/typography';

//Partials
@import './partials/headline';
@import './partials/text';
@import './partials/links';
@import './partials/buttons';
@import './partials/form';
@import './partials/backgrounds';
@import './partials/message';
@import './partials/modal';
@import './partials/navigation';
@import './partials/icon';
@import './partials/pagination';
@import './partials/quantity';
@import './partials/labels';
@import './partials/indicator';

//Components
@import './components/agegate';
@import './components/card';
@import './components/countryselector';
@import './components/cta-verticalNav';
@import './components/footer';
@import './components/footer-glo';
@import './components/glo-it-footer';
@import './components/footer-limited';
@import './components/footer-large';
@import './components/form';
@import './components/glors-header';
@import './components/gloitstepper-header';
@import './components/header';
@import './components/headline';
@import './components/hero';
@import './components/hero-narrow';
@import './components/htmlcontainer-livechat';
@import './components/image';
@import './components/minicart';
@import './components/messagebar';
@import './components/offers';
@import './components/cta';
@import './components/search';
@import './components/section';
@import './components/gloit-agegate';
@import './components/gloit-hero';
@import './components/gloit-card';
@import './components/gloitplp-hero';
@import './components/gloit-bottompositioncta';
@import './components/gloitinsiders-hero';
@import './components/gloitnewhyper-card';
@import './components/gloitnewhyper-hero';
@import './components/gloitartandmore-landing';
@import './components/gloit-map';
@import './components/gloitartandmore-hero';
@import './components/gloitportaunamico-hero';
@import './components/gloitbreakers-hero';
@import './components/gloitmclaren-hero';
@import './components/gloitdiscover-hero.scss';
@import './components/gloit-sidecard';
@import './components/gloitnewsletter-banner';
@import './components/gloit-minicart';
@import './components/gloit-storelocator';
@import './components/gloit-newsidecard';
@import './components/glors-signin';
@import './components/gloit-banner';
@import './components/gloit-mobilenav';
@import './components/glors-productlist';
@import './components/glors-sortfilter';
@import './components/glorsproducthero-pdp';
@import './components/glors-new-signup';
@import './components/glors-discovergloHowToUse.scss';
@import './components/glorsmap-storelocator';
@import './components/glors-hero';
@import './components/glors-carousel';
@import './components/glors-thrcard';
@import './components/glors-deleteaccount';
@import './components/glorsedit-addressbook';
@import './components/glorsadd-addressbook';
@import './components/glors-addressbok';
@import './components/glors-orderhistory';
@import './components/glors-confirmemail';
@import './components/glors-insidersclub';
@import './components/glors-loyaltyhistory';
@import './components/glors-cardloyaltyinfo';
@import './components/glors-imagegridcard';
@import './components/glors-entraclubmodal.scss';

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	background-color: $white;
	color: $blackish;
	text-rendering: optimizeLegibility;

	.bat-wrapper {
		overflow: hidden;

		@include media-breakpoint-up(lg) {

			.bat {
				display: block;
				margin-left: auto;
				margin-right: auto;
				max-width: $max-width-xxl;

				&.full-width {
					max-width: none;
				}
			}

			bat-header-default,
			bat-header-limited,
			bat-header-glo,
			bat-header-glors,
			bat-header-gloitstepper,
			bat-glo-it-header,
			bat-footer-velo,
			bat-footer-glo, 
			bat-footer-glors,
			bat-infoglocard-gloit,
			bat-usp-gloit,
			bat-banner-gloit,
			bat-socialgrid-gloit,
			bat-productcard-gloithome,
			bat-productcard-gloitblackfriday,
			bat-carousel-gloitproductcard,
			bat-engraving-gloit,
			bat-reviewslist-gloit,
			bat-productfeatures-gloitspecifications,
			bat-producthero-glors,
			bat-producthero-gloitmobile,
			bat-producthero-gloitsimplified,
			bat-hero-gloit,
			bat-artandmore-gloit,
			bat-portaunamico-gloit,
			bat-confronto-gloit,
			bat-card-gloitnewsletterbanner,
			bat-card-gloitnewsletterpopup,
			bat-storelocator-glors,
			bat-searchstorelocator-glors,
			bat-mapstorelocator-glors,
			bat-bloglist-gloit,
			bat-orderhistory-gloit,
			bat-orderdetails-gloit,
			bat-itemdetail-gloit,
			bat-blogdetail-gloit,
			bat-configuratorepannello-gloit,
			bat-form-gloitdiscoverglofeature,
			bat-form-gloitdiscoverglointro,
			bat-producthero-gloitproengraving{

				&.bat {
					max-width: none;
				}
			}
        }
	}

	bat-producthero-gloitproengraving {
		min-height: 800px;
	}

	.mobile-app.uk-modal-full.uk-modal {
		.uk-modal-body {
			height: 100%;
			max-height: 100%;
			overflow: hidden;

			.uk-modal-close-default {
				border-radius: 100%;
			}

			.survey-step-form {
				overflow-y: scroll;
				padding-bottom: 65px;
			}

			.bat.button-container {
				position: fixed;
				bottom: 32px;
				left: 0;
				width: 100%;
				padding: 0 20px;
				z-index: 1;

				.bat-cta-style {
					margin-bottom: 0;
    				width: 100%;
					
					@include media-breakpoint-down(md) {
						margin-bottom: 0;
    					width: 100%;
					}
				}
			}

			&.loyalty-success-modal {
				.modal-title {
					font-size: 28px;
					line-height: 36px;
				}

				.description-modal {
					font-weight: 500;
					font-size: 16px;
					line-height: 21px;
				}

				.bat.button-container {
					position: static;

					a {
						width: 92% !important;
					}
				}
			}
		}

		&#modal-quiz {
			.uk-modal-body {
				height: 70%;
				bottom: 0;
				position: absolute;
				border-radius: 40px 40px 0px 0px;
			
				.first-section-modal {
					margin-top: 20px !important;
				}
			}
		}

		&#modal-wrong-video {
			.bat.button-container {
				position: static;
			}
		}


	}

	.icon-coin {
		width: 23px;
		object-fit: contain;
	}
}

@include media-breakpoint-down(md) {
	#unyco_minified_chat {
		bottom: 40px !important;
	}
}
